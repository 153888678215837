import { Timeout } from "../decorators/timeout.decorator";
import { jSith } from "../util/jquery-replacement";
import { S25Util } from "../util/s25-util";
import { EventService } from "./event.service";
import { FlsService } from "./fls.service";
import { ResourceService } from "./resource.service";
import { SpaceService } from "./space.service";
import { Fls } from "../pojo/Fls";
import { Item } from "../pojo/Item";
import { Proto } from "../pojo/Proto";
import ObjectAvail = AvailCheck.ObjectAvail;
import ExtractedConflicts = AvailCheck.ExtractedConflicts;
import WSSpacesAvail = AvailWSResponse.WSSpacesAvail;
import ResourceConflict = AvailCheck.ResourceConflict;
import SpaceConflict = AvailCheck.SpaceConflict;
import Conflict = AvailWSResponse.Conflict;

const CONFLICT_LIMIT = 10;

type objectType = "space" | "resource";

// interface ObjectAvail
let normalizeConflict = function (conflict?: any) {
    return {
        conflict_id: conflict.conflict_id || conflict.also_conflict_id || conflict.subdivision_conflict_id,
        conflict_name: conflict.conflict_name || conflict.also_conflict_name || conflict.subdivision_conflict_name,
        conflict_type: conflict.conflict_type || conflict.also_conflict_type || conflict.subdivision_conflict_type,
        conflict_start: conflict.conflict_start || conflict.also_conflict_start || conflict.subdivision_conflict_start,
        conflict_end: conflict.conflict_end || conflict.also_conflict_end || conflict.subdivision_conflict_end,
        conflict_share: conflict.conflict_share || conflict.also_conflict_share || conflict.subdivision_conflict_share,
        conflict_category: conflict.conflict_id ? "direct" : conflict.also_conflict_id ? "also" : "subdivision",
    } as Conflict;
};

let conflictHashFn = function (conflict?: Conflict) {
    return (
        conflict.conflict_id +
        "&" +
        conflict.conflict_type +
        "&" +
        conflict.conflict_start +
        "&" +
        conflict.conflict_end +
        "&" +
        conflict.conflict_share +
        "&" +
        conflict.conflict_name +
        //note: remove date from hash fn bc it creates way too many conflict entries and we need not show them
        //all to the user. Instead we copy each conflict and add startDt to it as the candidateDate for the
        //conflict and we limit each object (sp/rs) rsrv to 10 conflicts each.
        ""
    ); // "&" + date;
};

function objectDateConflictHashFn(candidateId?: number, itemId?: number, itemTypeId?: number, conflict?: any) {
    return candidateId + "&" + itemId + "&" + itemTypeId + "&" + conflictHashFn(conflict);
}

function extractConflicts(objectAvail: any, objectType: objectType, fls?: Fls): ExtractedConflicts {
    //space/resource avail node and "space", "resource", then fls obj
    let conflictArr: any[] = [],
        objectConflictHash: any = {},
        resAvailDateMap: any = {},
        apNoRequestArr: any[] = [];
    let objectList = S25Util.propertyGet(objectAvail, objectType);
    jSith.forEach(objectList, function (_, object) {
        //id of object being requested as a candidate to add to event
        let candidateId = parseInt(object.space_id) || parseInt(object.resource_id);
        let hasConflicts = object.has_conflicts !== "F";

        let directConflicts: any = {};
        jSith.forEachObj((object.dates && S25Util.array.forceArray(object.dates)) || [], (obj) => {
            //ANG-4669 generate lowAP conflicts
            //can_schedule could be T if user has No Request/Unassign Perms, and could be F if perms start as No access and are elevated
            // use perm_name instead
            if (obj.perm_name === "No Request") {
                object.canRequest = false;
                apNoRequestArr.push({ itemId: candidateId, itemTypeId: objectType === "space" ? 4 : 6, ...obj });
            }

            jSith.forEachObj(obj?.conflict && S25Util.array.forceArray(obj.conflict), (conflict) => {
                let hash = conflictHashFn(normalizeConflict(conflict));
                directConflicts[hash] = true;
            });
        });

        jSith.forEach(["direct", "also", "subdivision"], function (_, relation) {
            let objects =
                relation === "direct"
                    ? S25Util.array.forceArray(object)
                    : object[relation === "also" ? "also_assign" : "subdivision_of"];
            jSith.forEach(objects, function (_, candidate) {
                //id of conflict
                let spaceId = parseInt(candidate.also_space_id || candidate.subdivision_space_id || candidate.space_id);
                let resourceId = parseInt(candidate.also_resource_id || candidate.resource_id);

                let itemId = spaceId || resourceId; //id of conflicting object (for also/subdiv, could be different from candidate id)
                let itemTypeId = spaceId ? 4 : 6; //itemTypeId of both candidate and conflict, which always have the same type

                //name of conflict
                let itemName =
                    candidate.also_space_name ||
                    candidate.subdivision_space_name ||
                    candidate.space_name ||
                    candidate.also_resource_name ||
                    candidate.resource_name;

                let prefix = relation === "direct" ? "" : relation + "_";
                jSith.forEach(candidate[prefix + "dates"], function (_, objectDate) {
                    let date = S25Util.date.parseDropTZ(objectDate[prefix + "start_dt"]);

                    if (objectType === "resource" && relation === "direct") {
                        resAvailDateMap[itemId + "&" + S25Util.date.toS25ISODateTimeStr(date)] = {
                            stockLevel: S25Util.coalesce(parseInt(objectDate.stock_level), null),
                            stockTotal: S25Util.coalesce(parseInt(objectDate.stock_total), null),
                        };
                    }

                    //set conflicts count if not set
                    if (
                        S25Util.isUndefined(objectDate[prefix + "conflicts"]) &&
                        objectDate[prefix + "conflict"] &&
                        objectDate[prefix + "conflict"].length
                    ) {
                        objectDate[prefix + "conflicts"] = objectDate[prefix + "conflict"].length;
                    }

                    if (parseInt(objectDate[prefix + "conflicts"])) {
                        //if any conflicts...
                        let conflicts = S25Util.array
                            .forceArray(S25Util.propertyGet(objectDate, prefix + "conflict"))
                            .filter(S25Util.isDefined);
                        jSith.forEach(conflicts, function (_, primitiveConflictObj) {
                            let conflict = normalizeConflict(primitiveConflictObj);

                            //resources do NOT have also_assign nodes in their direct conflicts list (the dates node)... why? who knows ...
                            //but locations do and we need to IGNORE any subdiv/also_assign conflicts NOT in the direct (dates) conflicts list
                            //we also need to go through subdiv/also_assign for locations in order to get the right location causing the conflict
                            let conflictHash = conflictHashFn(conflict);
                            if (objectType === "resource" || directConflicts[conflictHash]) {
                                //to uniquely process conflicts: form hash of candidate request + conflicting object and conflict details
                                let hash = objectDateConflictHashFn(candidateId, itemId, itemTypeId, conflict);

                                if (!objectConflictHash[hash]) {
                                    objectConflictHash[hash] = true;
                                    if (conflict.conflict_start && conflict.conflict_start.match(/^\d+:\d+(:\d+)*$/)) {
                                        conflict.conflict_start =
                                            S25Util.date.toS25ISODateStr(date) + "T" + conflict.conflict_start;
                                    }

                                    if (conflict.conflict_end && conflict.conflict_end.match(/^\d+:\d+(:\d+)*$/)) {
                                        conflict.conflict_end =
                                            S25Util.date.toS25ISODateStr(date) + "T" + conflict.conflict_end;
                                    }
                                    //we check hasConflicts here and NOT above bc resAvailDateMap needs to get set for resources
                                    if (hasConflicts) {
                                        conflictArr.push({
                                            candidateId: candidateId,
                                            itemId: itemId,
                                            itemName: itemName,
                                            itemTypeId: itemTypeId,
                                            uuid: hash,
                                            date: S25Util.date.toS25ISODateTimeStr(date),
                                            conflictRelation: relation,
                                            conflictHash: conflictHashFn(conflict),
                                            conflictId: conflict.conflict_id,
                                            conflictType: conflict.conflict_type, //rsrv, block, blackout, hours
                                            conflictName: conflict.conflict_name,
                                            conflictStart: S25Util.date.parseDropTZ(conflict.conflict_start),
                                            conflictEnd: S25Util.date.parseDropTZ(conflict.conflict_end),
                                            conflictShare: conflict.conflict_share === "T",
                                            conflictOverride: {
                                                blackout:
                                                    conflict.conflict_type === "blackout" && fls.SPACE_BLACK === "F",
                                                closed: conflict.conflict_type === "hours" && fls.SPACE_HOURS === "F",
                                                block: conflict.conflict_type === "block" && fls.SPACE_BLOCKED === "F",
                                            },
                                        });
                                    }
                                }
                            }
                        });
                    }
                });
            });
        });
    });
    return { conflicts: conflictArr, resAvailDateMap: resAvailDateMap, apNoRequest: apNoRequestArr };
}
export class ResourceSpaceAvailService {
    @Timeout
    public static checkAvailability(
        contextEventId?: number,
        contextProfileId?: number,
        reservations?: any, // Reservation//any //[Reservation, allResAvailDateMap: any]
    ): Promise<[ObjectAvail]> {
        reservations.allResAvailDateMap = {};
        return FlsService.getFls().then(function (fls) {
            let promises: Promise<any>[] = [];
            let spaceConflicts: { spaces: WSSpacesAvail }[] = [],
                resourceConflicts: any[] = [];
            let spacesToCheck: any = {};
            let resourcesToCheck: any = {};

            jSith.forEach(reservations, function (_, rsrv) {
                let startDt = S25Util.date.parseDropTZ(rsrv.reservation_start_dt);
                let endDt = S25Util.date.parseDropTZ(rsrv.reservation_end_dt);

                jSith.forEach(rsrv.space_reservation, function (_, spRsrv) {
                    let hash = spRsrv.space_id;
                    if (startDt && endDt) {
                        if (!spacesToCheck[hash]) {
                            spacesToCheck[hash] = { dates: [], itemId: spRsrv.space_id };
                        }
                        spacesToCheck[hash].dates.push({ startDt: startDt, endDt: endDt });
                    }
                });

                jSith.forEach(rsrv.resource_reservation, function (_, rsRsrv) {
                    let hash = rsRsrv.resource_id + "&" + rsRsrv.quantity;
                    if (startDt && endDt) {
                        if (!resourcesToCheck[hash]) {
                            resourcesToCheck[hash] = {
                                dates: [],
                                itemId: rsRsrv.resource_id,
                                quantity: rsRsrv.quantity,
                            };
                        }
                        resourcesToCheck[hash].dates.push({ startDt: startDt, endDt: endDt });
                    }
                });
            });

            let spaceDatesToCheck: any = {};
            jSith.forEach(spacesToCheck, function (_, spaceToCheck) {
                if (spaceToCheck.dates && spaceToCheck.dates.length) {
                    spaceToCheck.dates.sort(S25Util.shallowSortDates("startDt", "endDt")); //sort for consistent hash
                    // ANG-4417: Overlapping dates are not allowed, so fix any overlapping dates just for the check
                    let lastEnd: Date;
                    for (let date of spaceToCheck.dates) {
                        if (date.startDt < lastEnd) date.startDt = lastEnd;
                        lastEnd = date.endDt;
                    }
                    let key = spaceToCheck.dates
                        .map(function (date: any) {
                            return date.startDt + "&" + date.endDt;
                        })
                        .join("");
                    if (!spaceDatesToCheck[key]) {
                        spaceDatesToCheck[key] = [];
                    }
                    spaceDatesToCheck[key].push(spaceToCheck);
                }
            });

            jSith.forEach(spaceDatesToCheck, function (_, spaces) {
                let spaceIds = S25Util.propertyGetAll(spaces, "itemId");
                let datesToCheck = spaces[0].dates; //all should have the same dates to check due to our hashing above
                promises.push(
                    SpaceService.getSpaceDatesAvailability(
                        spaceIds,
                        datesToCheck,
                        contextEventId,
                        contextProfileId,
                    ).then(function (spaceAvail) {
                        spaceConflicts.push(spaceAvail);
                    }),
                );
            });

            let resourceDatesToCheck: any = {};
            jSith.forEach(resourcesToCheck, function (_, resourceToCheck) {
                if (resourceToCheck.dates && resourceToCheck.dates.length) {
                    resourceToCheck.dates.sort(S25Util.shallowSortDates("startDt", "endDt")); //sort for consistent hash
                    let key = resourceToCheck.dates
                        .map(function (date: any) {
                            return date.startDt + "&" + date.endDt;
                        })
                        .join("");
                    if (!resourceDatesToCheck[key]) {
                        resourceDatesToCheck[key] = [];
                    }
                    resourceDatesToCheck[key].push(resourceToCheck);
                }
            });

            jSith.forEach(resourceDatesToCheck, function (_, resources) {
                let datesToCheck = resources[0].dates;
                promises.push(
                    ResourceService.getResourceDatesAvailability(
                        resources,
                        datesToCheck,
                        contextEventId,
                        contextProfileId,
                    ).then(function (resourceAvail) {
                        resourceConflicts.push(resourceAvail);
                    }),
                );
            });

            return S25Util.all(promises).then(function () {
                //all conflict checks are done
                let allSpaceConflicts: SpaceConflict[] = [],
                    allResourceConflicts: ResourceConflict[] = [],
                    apNoRequest: any[] = [];

                jSith.forEach(spaceConflicts, function (_, spaceAvail) {
                    let extract = extractConflicts(spaceAvail, "space", fls);
                    allSpaceConflicts = allSpaceConflicts.concat(extract.conflicts);
                    apNoRequest = apNoRequest.concat(extract.apNoRequest);
                });

                jSith.forEach(resourceConflicts, function (_, resourceAvail) {
                    let extract = extractConflicts(resourceAvail, "resource", fls);
                    allResourceConflicts = allResourceConflicts.concat(extract.conflicts);
                    Object.assign(reservations.allResAvailDateMap, extract.resAvailDateMap);
                    apNoRequest = apNoRequest.concat(extract.apNoRequest);
                });

                jSith.forEach(reservations, function (_, rsrv) {
                    let startDt = S25Util.date.parseDropTZ(rsrv.reservation_start_dt);
                    let startDtStr = S25Util.date.toS25ISODateTimeStr(startDt);
                    let endDt = S25Util.date.parseDropTZ(rsrv.reservation_end_dt);

                    jSith.forEach(rsrv.space_reservation, function (_, spRsrv) {
                        let itemId = parseInt(spRsrv.space_id);
                        spRsrv.conflicts = [];
                        let conflictsHash: any = {};

                        jSith.forEach(apNoRequest, (key, lowAp) => {
                            if (
                                lowAp.itemTypeId === 4 &&
                                itemId === lowAp.itemId &&
                                S25Util.date.equal(
                                    S25Util.date.dropTZString(startDt),
                                    S25Util.date.dropTZString(lowAp.start_dt),
                                )
                            ) {
                                rsrv.apNoRequest = true;
                                spRsrv.apNoRequest = true;
                                spRsrv.apReason = lowAp.ap_reason;
                                spRsrv.apViolationDate = lowAp.start_dt;
                            }
                        });

                        jSith.forEach(allSpaceConflicts, function (_, conflict) {
                            //only add conflicts to this spRsrv for the same candidate conflict, overlapping dates and halt if we are adding too many
                            if (
                                spRsrv.conflicts.length < CONFLICT_LIMIT &&
                                itemId === conflict.candidateId &&
                                startDt < conflict.conflictEnd &&
                                endDt > conflict.conflictStart
                            ) {
                                if (!conflictsHash[conflict.conflictHash]) {
                                    conflictsHash[conflict.conflictHash] = true;

                                    let conflictCp: any = {};
                                    S25Util.merge(conflictCp, conflict);
                                    conflictCp.candidateDate = startDt;

                                    conflictCp.occUUID = rsrv.occUUID;

                                    conflictCp.conflictOverride.share =
                                        ["rsrv", "block"].indexOf(conflictCp.conflictType) > -1 &&
                                        ((spRsrv.always_shared === "T" &&
                                            (conflictCp.conflictShare || conflictCp.itemId === itemId)) ||
                                            (spRsrv.share === "T" &&
                                                conflictCp.conflictShare &&
                                                fls.EVENT_SHARE === "F"));

                                    conflictCp.isReal =
                                        !conflictCp.conflictOverride.share &&
                                        !conflictCp.conflictOverride.blackout &&
                                        !conflictCp.conflictOverride.closed &&
                                        !conflictCp.conflictOverride.block;

                                    reservations.hasConflicts = true;
                                    reservations.hasRealConflicts = reservations.hasRealConflicts || conflictCp.isReal;

                                    rsrv.hasConflicts = true;
                                    rsrv.hasRealConflicts = rsrv.hasRealConflicts || conflictCp.isReal;

                                    spRsrv.hasConflicts = true;
                                    spRsrv.hasRealConflicts = spRsrv.hasRealConflicts || conflictCp.isReal;

                                    spRsrv.conflicts.push(conflictCp);
                                }
                            }
                        });
                    });

                    jSith.forEach(rsrv.resource_reservation, function (_, rsRsrv) {
                        let itemId = parseInt(rsRsrv.resource_id);
                        rsRsrv.conflicts = [];
                        let conflictsHash: any = {};

                        jSith.forEach(apNoRequest, (key, lowAp) => {
                            if (
                                lowAp.itemTypeId === 6 &&
                                itemId === lowAp.itemId &&
                                S25Util.date.equal(
                                    S25Util.date.dropTZString(startDt),
                                    S25Util.date.dropTZString(lowAp.start_dt),
                                )
                            ) {
                                rsrv.apNoRequest = true;
                                rsRsrv.apNoRequest = true;
                                rsRsrv.apReason = lowAp.ap_reason;
                                rsRsrv.apViolationDate = lowAp.start_dt;
                            } else {
                            }
                        });

                        rsRsrv.isIncluded !== false &&
                            jSith.forEach(allResourceConflicts, function (_, conflict) {
                                if (
                                    rsRsrv.conflicts.length < CONFLICT_LIMIT && //cut off for conflicts on an rsRsrv
                                    itemId === conflict.candidateId && //same item
                                    startDt < conflict.conflictEnd && //times overlap
                                    endDt > conflict.conflictStart &&
                                    startDtStr === conflict.date //came from the same occurrence start date (important for overlapping resource req due to quantity)
                                ) {
                                    if (!conflictsHash[conflict.conflictHash]) {
                                        conflictsHash[conflict.conflictHash] = true;

                                        let conflictCp: any = {};
                                        S25Util.merge(conflictCp, conflict);
                                        conflictCp.candidateDate = startDt;

                                        conflictCp.occUUID = rsrv.occUUID;

                                        conflictCp.isReal = true;

                                        reservations.hasConflicts = true;
                                        reservations.hasRealConflicts =
                                            reservations.hasRealConflicts || conflictCp.isReal;

                                        rsrv.hasConflicts = true;
                                        rsrv.hasRealConflicts = rsrv.hasRealConflicts || conflictCp.isReal;

                                        rsRsrv.hasConflicts = true;
                                        rsRsrv.hasRealConflicts = rsRsrv.hasRealConflicts || conflictCp.isReal;

                                        rsRsrv.conflicts.push(conflictCp);
                                    }
                                }
                            });
                    });
                });

                return reservations;
            });
        });
    }

    //migrated from s25-event_details.xml (DraftCheckAvailability)
    public static getAvailabilityByEvent(eventIdOrData?: any) {
        return EventService.coerceToEventDataPromise(eventIdOrData, ["reservations"]).then(function (eventData) {
            return ResourceSpaceAvailService.checkAvailability(
                parseInt(eventData.event_id),
                eventData.profile &&
                    eventData.profile.length === 1 &&
                    parseInt(eventData.profile[0].profile_id || null),
                S25Util.propertyGetAll(eventData, "reservation"),
            );
        });
    }
}

export namespace AvailCheck {
    import DateElement = AvailWSResponse.DateElement;
    export type Conflict = SpaceConflict | ResourceConflict;
    export interface SpaceConflict extends ConflictDefaults {}
    export interface ResourceConflict extends ConflictDefaults {}

    export interface Reservation {
        occUUID: number;
        reservation_start_dt: Date;
        reservation_end_dt: Date;
        space_reservation: SpaceReservation[];
        resource_reservation: ResourceReservation[];
        allResAvailDateMap?: any;
    }
    export interface SpaceRsrvConflict extends SpaceReservation {
        hasConflicts: boolean;
        hasRealConflicts: boolean;
        ap_restrictions?: AP[];
        canRequest: boolean;
    }
    export interface ObjectAvail extends Reservation {
        hasConflicts: boolean;
        hasRealConflicts: boolean;
        canRequest: boolean;
        space_reservation: SpaceRsrvConflict[];
        resource_reservation: ResourceRsrvConflict[];
    }
    export interface SpaceReservation {
        space_id: number;
        share: string;
        always_shared: string;
        conflicts?: ConflictDefaults[];
    }
    export interface ResourceRsrvConflict extends ResourceReservation {
        //TODO: implement type for resource
    }
    export interface ResourceReservation {
        //TODO: implement type for resource
    }
    export interface AP {
        apNoRequest: boolean;
        reason: string; //"ap|dow|nowRequest
        perm_name: string; //"No Request" | "Request" | "Assign" | "Approve"
        unassign_perm: string;
    }

    export type conflictTypes = "rsrv" | "block" | "hours" | "blackout" | "ap";
    export interface ConflictDefaults {
        candidateDate: Date;
        candidateId: number;
        conflictEnd: Date;
        conflictHash: string;
        conflictId: number;
        conflictName: string;
        conflictOverride: ConflictOverride;
        conflictRelation: "direct" | "also";
        conflictShare: boolean;
        conflictStart: Date;
        conflictType: conflictTypes;
        date: string;
        isReal: boolean;
        itemId: number;
        itemName: string;
        itemTypeId: Item.Id;
        occUUID: string;
        uuid: string;
    }
    export interface ConflictOverride {
        blackout: boolean;
        closed: boolean;
        block: boolean;
        share: boolean;
    }
    export interface ExtractedConflicts {
        conflicts: ConflictDefaults[];
        resAvailDateMap: ResAvailDateMap;
        apNoRequest: DateElement[];
    }
    export interface ResAvailDateMap {}
}

export namespace AvailWSResponse {
    import ISODateString = Proto.ISODateString;
    import conflictTypes = AvailCheck.conflictTypes;

    export interface WSSpacesAvail {
        engine: string;
        pubdate: string;
        space: WSSpaceAvail[];
    }
    export interface WSSpaceAvail {
        has_conflicts: string;
        space_name: string;
        max_capacity: number;
        dates: DateElement[];
        space_id: number;
        formal_name: string;
        available_dates: number;
    }
    export interface DateElement {
        start_dt: Date;
        events_perm: string;
        can_schedule: "T" | "F";
        ap_reason: string;
        perm_name: string;
        conflicts: Conflict[];
        unassign_perm_name: string;
        share: number;
    }

    export interface Conflict {
        conflict_id?: number; //the reservation or blackout id, hours is empty
        conflict_name: string;
        conflict_type: conflictTypes; //AP not generated from space_avail.json
        conflict_start: ISODateString;
        conflict_end: ISODateString;
        conflict_share: "T" | "F"; //only rsrv conflicts can be shared
        conflict_category?: "direct" | "also" | "subdivision";
        perms?: { assign: string; unassign: string };
    }

    // export interface AlsoConflict extends Conflict {
    //     also_conflict_id: number; // || conflict.subdivision_conflict_id,
    //     also_conflict_name: string; // || conflict.subdivision_conflict_name,
    //     also_conflict_type: Type Of(Conflict.conflict_type);// || conflict.subdivision_conflict_type,
    //     also_conflict_start || conflict.subdivision_conflict_start,
    //     conflict_end: conflict.conflict_end || conflict.also_conflict_end || conflict.subdivision_conflict_end,
    //     conflict_share: conflict.conflict_share || conflict.also_conflict_share || conflict.subdivision_conflict_share,
    //     conflict_category: conflict.conflict_id ? "direct" : conflict.also_conflict_id ? "also" : "subdivision",
    // }
}
